/**
 * @generated SignedSource<<927e1f3feb0902313753aa572532626d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectState = "ACTIVE" | "ARCHIVED" | "%future added value";
export type ArchiveProjectInput = {
  id: string;
};
export type ArchiveProjectDialogMutation$variables = {
  input: ArchiveProjectInput;
};
export type ArchiveProjectDialogMutation$data = {
  readonly archiveProject: {
    readonly id: string;
    readonly state: ProjectState;
  };
};
export type ArchiveProjectDialogMutation = {
  response: ArchiveProjectDialogMutation$data;
  variables: ArchiveProjectDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "archiveProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchiveProjectDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArchiveProjectDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3132be47305eb369d7d7af91d14a7c23",
    "id": null,
    "metadata": {},
    "name": "ArchiveProjectDialogMutation",
    "operationKind": "mutation",
    "text": "mutation ArchiveProjectDialogMutation(\n  $input: ArchiveProjectInput!\n) {\n  archiveProject(input: $input) {\n    id\n    state\n  }\n}\n"
  }
};
})();

(node as any).hash = "58c9d7bac07f6b3c5742e4eacc38e8df";

export default node;
