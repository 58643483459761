/**
 * @generated SignedSource<<2ba06c907238f25f9db6f1a0b58b6cb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReactivateProjectDialog_project$data = {
  readonly id: string;
  readonly projectName: string;
  readonly projectNumber: string | null;
  readonly " $fragmentType": "ReactivateProjectDialog_project";
};
export type ReactivateProjectDialog_project$key = {
  readonly " $data"?: ReactivateProjectDialog_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReactivateProjectDialog_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReactivateProjectDialog_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectNumber",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "4f169c5f33aed2c963670b5b9cee988a";

export default node;
