/**
 * @generated SignedSource<<5764fa45bda07209a1d913d4abd90a9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectViewQuery$variables = {
  id: string;
};
export type ProjectViewQuery$data = {
  readonly project: {
    readonly id: string;
    readonly projectName: string;
    readonly projectNumber: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsView_project" | "ProjectHeader_project">;
  } | null;
};
export type ProjectViewQuery = {
  response: ProjectViewQuery$data;
  variables: ProjectViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectHeader_project"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectDetailsView_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAccount",
            "kind": "LinkedField",
            "name": "customerAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerName",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billingMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "language",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fixedFee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hourlyRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hoursBudget",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "costBudget",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "purchaseOrderReference",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customerOrderNotes",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d44863a2094c83d56b30e92eff87e8a0",
    "id": null,
    "metadata": {},
    "name": "ProjectViewQuery",
    "operationKind": "query",
    "text": "query ProjectViewQuery(\n  $id: ID!\n) {\n  project(id: $id) {\n    id\n    projectName\n    projectNumber\n    ...ProjectHeader_project\n    ...ProjectDetailsView_project\n  }\n}\n\nfragment ArchiveProjectDialog_project on Project {\n  id\n  projectName\n  projectNumber\n}\n\nfragment DeleteProjectDialog_project on Project {\n  id\n  projectName\n  projectNumber\n}\n\nfragment ProjectDetailsView_project on Project {\n  id\n  projectName\n  projectNumber\n  state\n  customerAccount {\n    id\n    customerName\n    customerNumber\n  }\n  language\n  billingMode\n  fixedFee\n  hourlyRate\n  hoursBudget\n  costBudget\n  purchaseOrderReference\n  customerOrderNotes\n  ...ArchiveProjectDialog_project\n  ...ReactivateProjectDialog_project\n  ...DeleteProjectDialog_project\n}\n\nfragment ProjectHeader_project on Project {\n  id\n  projectName\n  projectNumber\n  state\n  customerAccount {\n    customerName\n    id\n  }\n  billingMode\n}\n\nfragment ReactivateProjectDialog_project on Project {\n  id\n  projectName\n  projectNumber\n}\n"
  }
};
})();

(node as any).hash = "22b3fbdb3438868f1d599988c88b405c";

export default node;
