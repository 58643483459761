/**
 * @generated SignedSource<<8e591d214fbcaa47ca7b6c65fcfb036b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectBillingMode = "FIXED_FEE" | "NON_BILLABLE" | "TIME_AND_MATERIALS" | "%future added value";
export type ProjectState = "ACTIVE" | "ARCHIVED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectHeader_project$data = {
  readonly billingMode: ProjectBillingMode;
  readonly customerAccount: {
    readonly customerName: string;
  };
  readonly id: string;
  readonly projectName: string;
  readonly projectNumber: string | null;
  readonly state: ProjectState;
  readonly " $fragmentType": "ProjectHeader_project";
};
export type ProjectHeader_project$key = {
  readonly " $data"?: ProjectHeader_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectHeader_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectHeader_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingMode",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "53ee70a9e481a147b166ff8d5a16395e";

export default node;
