import * as React from "react"
import {useEffect} from "react"
import {graphql} from "react-relay";
import {usePaginationFragment} from "react-relay/hooks";
import LoadMoreButton from "./presentational/LoadMoreButton";
import Table from "./presentational/table/Table";
import TableHeader from "./presentational/table/TableHeader";
import TableData, {TextAlignment} from "./presentational/table/TableData";
import {resolvePath, useNavigate} from "react-router";
import ActionButton from "./presentational/button/ActionButton";
import {faFileMagnifyingGlass} from "@fortawesome/pro-regular-svg-icons";
import {hideModal, showModal} from "../store/actions";
import {connect, ConnectedProps} from "react-redux";
import Dinero from "dinero.js";
import StatusBadge from "./presentational/StatusBadge";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type Props = {
    queryRef: any // FIXME: Any type we can define here? It's actually: Readonly<{ ' $data'?: unknown; ' $fragmentRefs': unknown; }> | null
    searchTerm?: string
}

type ProjectListProps = ConnectedProps<typeof connector> & Props;

const ProjectList: React.FC<ProjectListProps> = (props) => {
    const {data, hasNext, loadNext, isLoadingNext, refetch} = usePaginationFragment(
        graphql`
            fragment ProjectList_query on Query
            @argumentDefinitions(
                first: { type: "Int", defaultValue: 100 }
                after: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
            )
            @refetchable(queryName: "ProjectsPaginationQuery") {
                projects(first: $first, after: $after, searchTerm: $searchTerm)
                @connection(key: "ProjectList_projects") {
                    totalCount
                    edges {
                        node {
                            id
                            projectName
                            projectNumber
                            state
                            customerAccountIdentifier
                            customerAccount {
                                id
                                customerName
                            }
                            billingMode
                            hourlyRate
                            fixedFee
                        }
                    }
                }
            }
        `,
        props.queryRef
    );
    const navigate = useNavigate();

    useEffect(() => {
        refetch({searchTerm: props.searchTerm}, {fetchPolicy: 'store-and-network'});
    }, [props.searchTerm, refetch])

    if (!data.projects || data.projects.totalCount === 0) {
        return (<></>);
    }

    return (
        <div className="flex-1 min-h-0 relative overflow-y-auto" aria-label="Customers">
            <Table>
                <thead>
                    <tr>
                        <TableHeader>State</TableHeader>
                        <TableHeader>Project No.</TableHeader>
                        <TableHeader>Project</TableHeader>
                        <TableHeader>Customer</TableHeader>
                        <TableHeader className="text-right">Rate</TableHeader>
                        <TableHeader/>
                    </tr>
                </thead>
                <tbody>
                    {data.projects.edges.map(
                        (edge, index) => {
                            if (!edge.node) { // why can this happen?
                                return null;
                            }
                            const project = edge.node;
                            const {customerAccount} = project;
                            const {customerName} = customerAccount;
                            const projectRate = Dinero({amount: project.hourlyRate}).toFormat() + ' / h'

                            return (
                                <tr key={index} className={(index % 2 === 0 ? 'bg-white' : 'bg-gray-50') + ' hover:bg-gray-100 group'}>
                                    <TableData>
                                        {project.state === 'ARCHIVED' && (<StatusBadge value="Archived" states={{}}/>)}
                                        {project.state === 'ACTIVE' && (<StatusBadge value="Active" states={{green: ['Active']}}/>)}
                                    </TableData>
                                    <TableData>{project.projectNumber}</TableData>
                                    <TableData>{project.projectName}</TableData>
                                    <TableData>{customerName}</TableData>
                                    <TableData textAlignment={TextAlignment.Right}>
                                        {project.billingMode === 'TIME_AND_MATERIALS' && projectRate}
                                        {project.billingMode === 'NON_BILLABLE' && (<StatusBadge value="Non Billable" states={{}}/>)}
                                        {project.billingMode === 'FIXED_FEE' && (<StatusBadge value="Fixed Fee" states={{green: ['Fixed Fee']}}/>)}
                                    </TableData>
                                    <TableData className="hidden sm:table-cell" textAlignment={TextAlignment.Right}>
                                        <ActionButton additionalClassname="invisible group-hover:visible" icon={faFileMagnifyingGlass} title="Show project" onClick={(event) => {
                                            let to = "/projects/" + project.id;
                                            if (event.shiftKey || event.metaKey) {
                                                event.preventDefault()
                                                window.open(resolvePath(to).pathname)
                                            } else {
                                                navigate(to)
                                            }
                                        }}/>
                                    </TableData>
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </Table>
            {hasNext && <LoadMoreButton onClick={() => loadNext(100)} isLoadingNext={isLoadingNext}/>}
        </div>
    )
}

export default connector(ProjectList);
