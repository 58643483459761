/**
 * @generated SignedSource<<48832eef65eea7a712aa3773fd5cfe20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveProjectDialog_project$data = {
  readonly id: string;
  readonly projectName: string;
  readonly projectNumber: string | null;
  readonly " $fragmentType": "ArchiveProjectDialog_project";
};
export type ArchiveProjectDialog_project$key = {
  readonly " $data"?: ArchiveProjectDialog_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveProjectDialog_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveProjectDialog_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectNumber",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "b80271764af3637b41404d03eb0981db";

export default node;
