import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import {useNavigate} from "react-router-dom";

type ArchiveProjectDialogProps = {
    queryRef: any,
    handleCloseDialog: () => {}
}

const ArchiveProjectDialog: React.FC<ArchiveProjectDialogProps> = (props) => {

    const navigate = useNavigate();
    const project = useFragment(
        graphql`
            fragment ArchiveProjectDialog_project on Project {
                id
                projectName
                projectNumber
            }
        `,
        props.queryRef
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation ArchiveProjectDialogMutation(
                        $input: ArchiveProjectInput!
                    ) {
                        archiveProject(input: $input) {
                            id
                            state
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                    navigate('/projects')
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        id: project.id
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog}
                    handleSubmission={handleSubmission}
                    initialValues={initialValues}
                    headline="Archive Project"
                    submitLabel="Archive">
            <Section>
                <Row>
                    <p className="text-gray-900 font-medium">
                        Really archive project "{project.projectName}" {project.projectNumber ? '(' + project.projectNumber + ')' : ''}?
                    </p>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default ArchiveProjectDialog;
