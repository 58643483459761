/**
 * @generated SignedSource<<e8997165edb2abf98a82a3e4a3ae8774>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectBillingMode = "FIXED_FEE" | "NON_BILLABLE" | "TIME_AND_MATERIALS" | "%future added value";
export type ProjectState = "ACTIVE" | "ARCHIVED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectDetailsView_project$data = {
  readonly billingMode: ProjectBillingMode;
  readonly costBudget: number | null;
  readonly customerAccount: {
    readonly customerName: string;
    readonly customerNumber: string;
    readonly id: string;
  };
  readonly customerOrderNotes: string | null;
  readonly fixedFee: number | null;
  readonly hourlyRate: number;
  readonly hoursBudget: number | null;
  readonly id: string;
  readonly language: string | null;
  readonly projectName: string;
  readonly projectNumber: string | null;
  readonly purchaseOrderReference: string | null;
  readonly state: ProjectState;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveProjectDialog_project" | "DeleteProjectDialog_project" | "ReactivateProjectDialog_project">;
  readonly " $fragmentType": "ProjectDetailsView_project";
};
export type ProjectDetailsView_project$key = {
  readonly " $data"?: ProjectDetailsView_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDetailsView_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDetailsView_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fixedFee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hourlyRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursBudget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costBudget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purchaseOrderReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerOrderNotes",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchiveProjectDialog_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReactivateProjectDialog_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteProjectDialog_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "6ac04be95a3418f5d46028cd4179d8af";

export default node;
